<template>
  <div v-if="currentPatient">
    <ion-row class="pad-bottom-twenty grid-layout">
      <ion-col class="column-grid-layout">
        <div>
          <ion-card color="white" class="pad-twenty margin-death" style="border:0px solid black">
            <ion-row class="pad-bottom-twenty">
              <ion-col>
                <p class="margin-death text-bold">Engagement</p>
              </ion-col>
              <ion-col></ion-col>
              <ion-col size="auto">
                <select
                  class="form-control"
                  style="display:block; max-width:100%"
                  @change="getMessageStats($event)"
                >
                  <option value="1">1 week</option>
                  <option value="2">2 weeks</option>
                  <option value="3">1 month</option>
                  <option value="4">all</option>
                </select>
              </ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bottom-divider-thin pad-top-ten pad-bottom-ten">
              <ion-col size="auto" class="valign">
                <IconChatBubble class="icon-small" />
              </ion-col>
              <ion-col class="pad-lr-ten">
                <span class="text-bold">Total Messages From Patient</span>
              </ion-col>
              <ion-col size="auto">
                <span class="text-blue text-large text-bold">{{ messagesTotal || 0 }}</span>
              </ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bottom-divider-thin pad-top-ten pad-bottom-ten">
              <ion-col size="auto" class="valign">
                <IconChatBubble class="icon-small" />
              </ion-col>
              <ion-col class="pad-lr-ten">
                <span class="text-bold">Direct Care Team Messages</span>
              </ion-col>
              <ion-col size="auto">
                <span class="text-blue text-large text-bold">{{
                  messagesDirectCareTeam || 0
                }}</span>
              </ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bottom-divider-thin pad-top-ten pad-bottom-ten">
              <ion-col size="auto" class="valign">
                <IconChatBubble class="icon-small" />
              </ion-col>
              <ion-col class="pad-lr-ten">
                <span class="text-bold">Program Messages</span>
              </ion-col>
              <ion-col size="auto">
                <span class="text-blue text-large text-bold">{{ messagesProgram || 0 }}</span>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="pad-top-ten">
                <button
                  @click="goToNamedRoute('conversations')"
                  class="prime-button button-block button-secondary"
                >
                  Go To Conversations
                </button>
              </ion-col>
            </ion-row>
          </ion-card>
        </div>
        <div>
          <ion-card color="white" class="pad-twenty margin-death">
            <ion-row class="pad-bottom-twenty ion-align-items-center">
              <ion-col>
                <p class="margin-death text-bold">Tasks</p>
              </ion-col>
              <ion-col></ion-col>
            </ion-row>
            <div v-if="isTasksLoading" class="text-center pad-ten loading-overlay center-center">
              <ion-spinner name="lines" class="spinner-xlarge" color="dark"></ion-spinner>
            </div>
            <ion-row
              v-if="tasksList.length == 0"
              class="ion-align-items-center margin-lr-ten pad-bottom-ten pad-top-ten"
            >
              <ion-col class="pad-lr-ten">
                <p class="text-muted text-center text-bold">No open tasks</p>
              </ion-col>
            </ion-row>
            <ion-row
              class="ion-align-items-center bottom-divider-thin margin-lr-twenty pad-top-twenty pad-bottom-twenty cursor-pointer"
              @click="openDescriptionModal(task)"
              v-for="(task, index) in tasksList.slice(0, 5)"
              :key="index"
            >
              <ion-col size="auto" class="valign">
                <div
                  class="prime-task-checkbox"
                  :class="{ checked: task.task.status == 'completed' }"
                ></div>
              </ion-col>
              <ion-col class="pad-lr-ten">
                <div class="text-bold">{{ task.task.name }}</div>
                <div class="text-small text-muted">
                  {{ task.task.createdAt | moment("from", "now", true) }} old
                </div>
              </ion-col>
            </ion-row>
            <ion-row class="pad-top-twenty margin-lr-ten">
              <ion-col class="pad-top-ten">
                <button
                  v-if="$can(I.MODIFY_PATIENT_DATA)"
                  @click="
                    openNewTaskModal('Create a New Task', currentPatient.payload.beneficiary.id)
                  "
                  class="prime-button button-block button-primary"
                >
                  Create New Task
                </button>
                <button
                  v-else
                  class="prime-button disabled button-block button-primary"
                  v-tooltip.top-start="{
                    content: $orgMissingPermissions('The Creating Tasks feature')
                  }"
                >
                  Create New Task
                </button>
              </ion-col>
            </ion-row>
            <ion-row class="margin-lr-ten pad-bottom-ten">
              <ion-col class="pad-top-ten">
                <button
                  @click="goToNamedRoute('tasks')"
                  class="prime-button button-block button-secondary"
                >
                  View All Tasks
                </button>
              </ion-col>
            </ion-row>
          </ion-card>
        </div>
        <div>
          <ion-card color="white" class="pad-twenty margin-death">
            <ion-row class="pad-bottom-twenty ion-align-items-center">
              <ion-col>
                <p class="margin-death text-bold">Programs</p>
              </ion-col>
              <ion-col></ion-col>
            </ion-row>
            <div v-if="activePrograms.length > 0">
              <ion-row
                class="bottom-divider-thin ion-align-items-center pad-bottom-ten pad-top-ten"
                v-for="(program, index) in activePrograms"
                :key="index"
              >
                <ion-col class="pad-lr-ten">
                  <span class="text-bold">{{ program.program.name }}</span>
                </ion-col>
                <ion-col size="auto">
                  <div class="text-muted text-small">Added</div>
                  <div class="text-bold text-small">
                    {{ program.accountProgram.activated | moment("M/DD/YYYY") }}
                  </div>
                </ion-col>
              </ion-row>
            </div>
            <div v-else>
              <ion-row
                class="bottom-divider-thin ion-align-items-center pad-bottom-ten pad-top-ten"
              >
                <ion-col class="pad-lr-ten">
                  <span class="text-small text-muted">No active programs.</span>
                </ion-col>
              </ion-row>
            </div>
            <ion-row>
              <ion-col class="pad-top-ten">
                <button
                  @click="goToNamedRoute('programs')"
                  class="prime-button button-block button-secondary"
                >
                  Go To Programs
                </button>
              </ion-col>
            </ion-row>
          </ion-card>
        </div>
        <div v-if="$can(I.VIEW_EVENT_NOTIFICATIONS)">
          <ion-card color="white" class="pad-twenty margin-death">
            <ion-row class="pad-bottom-twenty">
              <ion-col>
                <p class="margin-death text-bold">Event Notifications</p>
              </ion-col>
              <ion-col></ion-col>
            </ion-row>
            <div v-if="isADTsLoading" class="text-center pad-ten loading-overlay center-center">
              <ion-spinner name="lines" class="spinner-xlarge" color="dark"></ion-spinner>
            </div>
            <div v-else>
              <ion-row class="margin-bottom-twenty">
                <ion-col>
                  <span class="text-muted">Past 30 Days</span>
                </ion-col>
              </ion-row>
              <ion-row
                v-if="!adts"
                class="ion-align-items-center margin-lr-ten pad-bottom-ten pad-top-ten"
              >
                <ion-col class="pad-lr-ten">
                  <p class="text-muted text-center text-bold">No ADTs available.</p>
                </ion-col>
              </ion-row>
              <div
                v-for="adt of adts"
                :key="adt.modelId"
                class="margin-bottom-ten pad-bottom-ten bottom-divider-thin vgt-wrap"
              >
                <ion-row
                  @click="getDetails(adt.correlationId)"
                  style="cursor: pointer"
                  class="ion-align-items-center pad-top-ten pad-bottom-ten"
                >
                  <ion-col class="icon-col">
                    <IconWarning class="icon-small" />
                  </ion-col>
                  <ion-col>
                    <ion-row class="margin-bottom-ten">
                      <span class="text-bold">{{ adt.eventTitle }}</span>
                    </ion-row>
                    <ion-row class="margin-top-five">
                      <ion-col>
                        <span class="text-muted">Type: </span
                        ><span class="text-bold margin-right-twenty">{{ adt.eventType }}</span>
                        <span class="text-muted">Date: </span
                        ><span class="text-bold">{{ utcFormat(adt.eventDate) }}</span>
                      </ion-col>
                    </ion-row>
                  </ion-col>
                </ion-row>
              </div>
            </div>
            <ion-col class="pad-top-ten">
              <button
                @click="goToNamedRoute('event-notifications')"
                class="prime-button button-block button-secondary"
              >
                Go To Notification
              </button>
            </ion-col>
          </ion-card>
        </div>
        <div>
          <ion-card color="white" class="pad-twenty margin-death">
            <ion-row class="pad-bottom-twenty ion-align-items-center">
              <ion-col>
                <p class="margin-death text-bold">Care Team</p>
              </ion-col>
              <ion-col></ion-col>
            </ion-row>
            <ion-row v-if="careTeam.length > 0">
              <ion-col v-for="(member, index) in careTeam" :key="index" class="pad-bottom-ten">
                <div
                  class="prime-card full-height text-center pad-ten margin-right-five margin-five"
                >
                  <div class="avatar center" v-if="member.firstName && member.lastName">
                    {{ member.firstName.charAt(0) + member.lastName.charAt(0) }}
                  </div>
                  <div
                    class="text-bold text-small margin-top-ten"
                    v-if="member.firstName && member.lastName"
                  >
                    {{ member.firstName + " " + member.lastName }}
                  </div>
                  <div class="text-muted text-small" v-if="member.assignedProfessional">
                    Assigned Professional
                  </div>
                  <div class="text-muted text-small" v-else-if="member.defaultCareTeamMember">
                    Core Care Team Member
                  </div>
                  <div class="text-muted text-small" v-else-if="member.careTeamMember">
                    Care Team Member
                  </div>
                </div>
              </ion-col>
            </ion-row>
            <ion-row
              v-else
              class="bottom-divider-thin ion-align-items-center pad-bottom-ten pad-top-ten"
            >
              <ion-col class="pad-lr-ten">
                <span class="text-small text-muted">No care team members.</span>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="pad-top-ten">
                <button
                  @click="goToNamedRoute('care-team')"
                  class="prime-button button-block button-secondary"
                >
                  Edit Care Team
                </button>
              </ion-col>
            </ion-row>
          </ion-card>
        </div>
      </ion-col>
      <ion-col
        v-if="$can(I.VIEW_GAPS_IN_CARE) || $can(I.VIEW_DX_INSIGHTS)"
        class="column-grid-layout"
      >
        <div v-if="$can(I.VIEW_DX_INSIGHTS)">
          <ion-card color="white" class="pad-twenty margin-death">
            <ion-row class="pad-bottom-twenty ion-align-items-center">
              <ion-col>
                <p class="margin-death text-bold">Diagnosis Considerations</p>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="dx-container">
                <DiagnosisConsiderationWrapper
                  :beneficiaryId="beneficiaryId"
                  :showSubcategoryTitle="false"
                />
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="pad-top-twenty">
                <button
                  @click="goToNamedRoute('diagnosis-considerations')"
                  class="prime-button button-block button-secondary"
                >
                  Go To Diagnosis Considerations
                </button>
              </ion-col>
            </ion-row>
          </ion-card>
        </div>
        <div v-if="$can(I.VIEW_GAPS_IN_CARE)">
          <ion-card color="white" class="pad-twenty margin-death">
            <ion-row class="pad-bottom-ten ion-align-items-center">
              <ion-col>
                <p class="margin-death text-bold">Quality Considerations</p>
              </ion-col>
            </ion-row>
            <GapsInCare :currentPatient="currentPatient" :isCarded="true" />
            <ion-row>
              <ion-col class="pad-top-ten">
                <button
                  @click="goToNamedRoute('quality-considerations')"
                  class="prime-button button-block button-secondary"
                >
                  Go To Quality Considerations
                </button>
              </ion-col>
            </ion-row>
          </ion-card>
        </div>
      </ion-col>
      <ion-col v-if="$can(I.VIEW_RISK_DRIVERS) || $can(I.VIEW_SDOH)" class="column-grid-layout">
        <div v-if="$can(I.VIEW_RISK_DRIVERS)">
          <ion-card color="white" class="pad-twenty margin-death">
            <ion-row class="pad-bottom-twenty ion-align-items-center">
              <ion-col>
                <p class="margin-death text-bold">Risk Models</p>
              </ion-col>
            </ion-row>
            <div v-if="isRisksLoading" class="text-center pad-ten loading-overlay center-center">
              <ion-spinner name="lines" class="spinner-xlarge" color="dark"></ion-spinner>
            </div>
            <ion-row
              v-if="!risks"
              class="ion-align-items-center margin-lr-ten pad-bottom-ten pad-top-ten"
            >
              <ion-col class="pad-lr-ten">
                <p class="text-muted text-center text-bold">No Risks available.</p>
              </ion-col>
            </ion-row>
            <div
              v-for="(risk, riskKey) of risks"
              :key="risk.modelId"
              class="margin-bottom-ten pad-bottom-ten bottom-divider-thin vgt-wrap"
            >
              <ion-row
                class="ion-align-items-center pad-bottom-ten pad-top-ten pill-wrap padding-death cursor-pointer"
              >
                <ion-col
                  class="pad-lr-ten"
                  @click="openRiskDriversModal(risk, currentPatient.payload.beneficiary.id)"
                >
                  <div class="text-bold pad-bottom-five trend">
                    {{ riskKey }}
                    <IconInformation v-if="risk.description" v-tooltip="risk.description" />
                  </div>
                  <div
                    class="flex-section pill risk-pill"
                    :class="getStatusClass(risk.predictionLevel)"
                  >
                    <div class="main-val">{{ risk.prediction ? risk.prediction : "Unknown" }}</div>
                    <div v-if="risk.trend === 'Rising'" class="trend pill">
                      <IconRisingTrend />{{ risk.trend }}
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </div>
          </ion-card>
        </div>
        <div v-if="$can(I.VIEW_SDOH)">
          <ion-card color="white" class="pad-twenty margin-death">
            <ion-row class="pad-bottom-twenty ion-align-items-center">
              <ion-col>
                <p class="margin-death text-bold">Social Determinants of Health</p>
              </ion-col>
            </ion-row>
            <div v-if="isSDoHLoading" class="text-center pad-ten loading-overlay center-center">
              <ion-spinner name="lines" class="spinner-xlarge" color="dark"></ion-spinner>
            </div>
            <ion-row
              v-if="!sdohs"
              class="ion-align-items-center margin-lr-ten pad-bottom-ten pad-top-ten"
            >
              <ion-col class="pad-lr-ten">
                <p class="text-muted text-center text-bold">No SDoH available.</p>
              </ion-col>
            </ion-row>
            <div
              v-for="(sdoh, sdohKey) of sdohs"
              :key="sdoh.modelId"
              class="margin-bottom-ten pad-bottom-ten bottom-divider-thin"
            >
              <ion-row
                class="ion-align-items-center pad-bottom-ten pad-top-ten pill-wrap padding-death cursor-pointer"
              >
                <ion-col
                  class="pad-lr-ten"
                  size="7"
                  @click="openSdohModal(sdoh, currentPatient.payload.beneficiary.id)"
                >
                  <div class="text-bold pad-bottom-five">{{ sdohKey }}</div>
                  <div v-if="sdoh.assessments" class="text-small">
                    <span class="text-muted">Last Assessed: </span
                    ><span class="text-bold"
                      >{{ sdoh.assessments[0].createdAt | moment("MMM DD, YYYY") }}2</span
                    >
                  </div>
                </ion-col>
                <ion-col size="5" class="flex-section">
                  <div v-if="!checkNeedConfirmed(sdoh)" class="indicates">
                    <span v-tooltip="'Needs Assessment/Confirmation'">
                      <IconInformation />
                    </span>
                  </div>
                  <div
                    v-if="checkNeedConfirmed(sdoh)"
                    class="pill text-bold text-center text-capitalize unclickable"
                    :class="{
                      'no-need': sdoh.assessments[0].hasNeed === false,
                      'need-confirmed': sdoh.assessments[0].hasNeed === true
                    }"
                  >
                    {{ sdoh.assessments[0].hasNeed ? "Need Confirmed" : "No Need" }}
                  </div>
                  <div
                    v-else
                    class="pill text-bold text-center text-capitalize unclickable"
                    :class="getStatusClass(sdoh.predictionLevel)"
                  >
                    {{ sdoh.prediction ? sdoh.prediction : "Unknown" }}
                  </div>
                </ion-col>
              </ion-row>
            </div>
          </ion-card>
        </div>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import DiagnosisConsiderationWrapper from "@/components/Settings/Patient/DiagnosisConsiderationWrapper";
import ModalNewMessage from "@/components/Chat/ModalNewMessage";
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";
import IconInformation from "@/components/Global/Icons/IconInformation";
import IconRisingTrend from "@/components/Global/Icons/IconRisingTrend";
import IconWarning from "@/components/Global/Icons/IconWarning";
import ModalAlerts from "@/components/Settings/Patient/ModalAlerts";
import ModalEventNotification from "@/components/Settings/Patient/ModalEventNotification";
import ModalRiskDrivers from "@/components/Settings/Patient/ModalRiskDrivers";
import ModalSdoh from "@/components/Settings/Patient/ModalSdoh";
import ModalNewTask from "@/components/Tasks/ModalNewTask";
import ModalTaskDescription from "@/components/Tasks/ModalTaskDescription";
import { send as httpSend } from "@/services/Api";
import GetTasksList from "@/services/Api/getTasksList";
import { EventBus } from "@/services/Events.js";
import { can, I } from "@/utils/permissions";
import moment from "moment";
import GapsInCare from "./GapsInCare";
import { findClass } from "./RiskMap";

export default {
  name: "GeneralInfo",
  props: {
    currentPatient: Object
  },
  data() {
    return {
      copy: this.$languagePack,
      formIsChanged: false,
      loading: false,
      isTasksLoading: false,
      isSDoHLoading: false,
      isRisksLoading: false,
      isADTsLoading: false,
      isDiagnosisConsiderationLoading: false,
      newMessageModal: ModalNewMessage,
      modalAlerts: ModalAlerts,
      ModalRiskDrivers: ModalRiskDrivers,
      messagesTotal: undefined,
      messagesDirectCareTeam: undefined,
      messagesProgram: undefined,
      activePrograms: [],
      tasksList: [],
      serverParams: {
        id: "0",
        page: "1",
        perPage: "100",
        columnFilters: {
          status: "created,assigned,in_progress",
          program: "",
          organization: "",
          provider: "",
          assignedTo: "",
          vip: "",
          search: null,
          beneficiary: this.currentPatient.payload.beneficiary.id
        },
        sort: []
      },
      isHighRisk: false,
      risks: null,
      sdohs: null,
      adts: null
    };
  },
  created() {
    EventBus.$on("updatedSdoh", () => {
      if (can(I.VIEW_SDOH)) {
        this.getSdoh();
      }
    });
  },
  beforeMount() {
    if (can(I.VIEW_SDOH)) {
      this.getSdoh();
    }
  },
  async mounted() {
    this.getMessageStats(1);
    this.getPrograms();
    if (can(I.VIEW_EVENT_NOTIFICATIONS)) {
      this.getADT();
    }
    this.getTasks(this.serverParams);

    EventBus.$on("getTasks", () => {
      this.getTasks(this.serverParams);
    });
  },
  components: {
    IconChatBubble,
    IconInformation,
    IconRisingTrend,
    GapsInCare,
    IconWarning,
    DiagnosisConsiderationWrapper
  },
  methods: {
    getMessageStats: async function(rangeCode) {
      this.messagesTotal = await this.messageStats(
        this.currentPatient.payload.beneficiary.accountId,
        "beneficiary",
        this.decodeMessageRange(rangeCode || 1)
      );
      this.messagesDirectCareTeam = await this.messageStats(
        this.currentPatient.payload.beneficiary.accountId,
        "professional",
        this.decodeMessageRange(rangeCode || 1)
      );
      this.messagesProgram = await this.messageStats(
        this.currentPatient.payload.beneficiary.accountId,
        "system",
        this.decodeMessageRange(rangeCode || 1)
      );
    },
    messageStats: async function(accountId, source, fromDate) {
      return new Promise(async function(resolve, reject) {
        const method = "get";
        const path =
          document.config.messagesStats +
          "?accountId=" +
          accountId +
          "&source=" +
          source +
          "&from=" +
          fromDate;

        httpSend({ path, method, authToken: true })
          .then(result => {
            resolve(result.data.count);
          })
          .catch(error => {
            khanSolo.log(error);
            reject(error);
          });
      });
    },
    async getPrograms() {
      const method = "get";
      const path =
        document.config.patientApi + this.currentPatient.payload.beneficiary.id + "/programs";

      httpSend({ path, method, authToken: true })
        .then(response => {
          let rtnArr = response.data;
          if (rtnArr.length > 0) {
            // first remove text triage
            this.allAvailablePrograms = rtnArr.filter(e => e.accountProgram.programId !== 1);
            //active programs
            this.activePrograms = this.allAvailablePrograms.filter(
              e => e.accountProgram.active == true
            );
          }
        })
        .catch(error => {
          khanSolo.log(error);
        });
    },
    checkboxClick: function(task) {
      task.status = "completed";
      this.updateStatus(task.id, "completed");
    },
    async updateStatus(taskId, value) {
      this.isTableLoading = true;
      const data = {
        id: taskId,
        action: "status",
        payload: {
          status: value
        },
        callback: this.showToast
      };
      await this.$store.dispatch("tasks/updateSingleTask", data);
    },
    decodeMessageRange(msgRange) {
      if (msgRange == 1) {
        return moment(new Date())
          .subtract(1, "w")
          .format("YYYY-MM-DD");
      } else if (msgRange == 2) {
        return moment(new Date())
          .subtract(2, "w")
          .format("YYYY-MM-DD");
      } else if (msgRange == 3) {
        return moment(new Date())
          .subtract(1, "m")
          .format("YYYY-MM-DD");
      } else {
        return "2017-09-28";
      }
    },
    openDescriptionModal: async function(task) {
      this.$ionic.modalController
        .create({
          component: ModalTaskDescription,
          cssClass: "prime-modal-xwide",
          mode: "ios",
          componentProps: {
            propsData: {
              taskId: task.task.id
            }
          }
        })
        .then(m => m.present());
    },
    openNewTaskModal(title, beneficiaryId) {
      this.$ionic.modalController
        .create({
          component: ModalNewTask,
          mode: "ios",
          componentProps: {
            propsData: {
              title: title,
              beneficiaryId: beneficiaryId
            }
          }
        })
        .then(m => m.present());
    },
    async getTasks(params) {
      this.isTasksLoading = true;
      try {
        const fullPayload = await GetTasksList(params);
        this.tasksList = fullPayload.results;
      } finally {
        this.isTasksLoading = false;
      }
    },
    openModal(componentName, id) {
      this.$ionic.modalController
        .create({
          component: componentName,
          mode: "ios",
          componentProps: {
            data: {
              id
            }
          }
        })
        .then(m => m.present());
    },
    openSdohModal(sdohObj, beneId) {
      this.$ionic.modalController
        .create({
          component: ModalSdoh,
          mode: "ios",
          componentProps: {
            propsData: {
              sdohObj,
              beneficiaryId: beneId
            }
          }
        })
        .then(m => m.present());
    },
    async openModalNotification(detailsObj, beneId) {
      const modal = await this.$ionic.modalController.create({
        component: ModalEventNotification,
        mode: "ios",
        componentProps: {
          propsData: {
            detailsObj,
            beneId
          }
        },
        backdropDismiss: false
      });

      return await modal.present();

    },
    openRiskDriversModal(riskObj, beneId) {
      this.$ionic.modalController
        .create({
          component: ModalRiskDrivers,
          mode: "ios",
          componentProps: {
            propsData: {
              riskObj,
              beneficiaryId: beneId
            }
          }
        })
        .then(m => m.present());
    },
    goToNamedRoute(routeName) {
      this.$router.push({
        name: routeName
      });
    },
    getSdoh() {
      const method = "get";
      const path = document.config.sdoh + this.currentPatient.payload.beneficiary.id;
      this.isSDoHLoading = true;
      this.isRisksLoading = true;

      httpSend({ path, method, authToken: true })
        .then(response => {
          if (response.status != 200) {
            throw new Error("Unreachable");
          }

          this.risks = response.data.risks ? this.sortObjectKeys(response.data.risks) : {};
          this.sdohs = response.data.sdoh ? this.sortObjectKeys(response.data.sdoh) : {};
          this.isSDoHLoading = false;
          this.isRisksLoading = false;
        })
        .catch(error => {
          this.isSDoHLoading = false;
          this.isRisksLoading = false;
          this.$ionic.toastController
            .create({
              header: "Error!",
              message: "There was a problem getting data.",
              duration: 4000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    utcFormat(date) {
      return moment(date)
        .utc()
        .format("MM/DD/YYYY - h:mmA");
    },
    getADT() {
      const method = "get";
      const path =
        document.config.patientApi +
        this.currentPatient.payload.beneficiary.id +
        "/event/quick_history";
      this.isADTsLoading = true;

      httpSend({ path, method, authToken: true })
        .then(response => {
          if (response.status != 200) {
            throw new Error("Unreachable");
          }

          if (response.data.length > 0) {
            this.adts = this.sortObjectKeys(response.data);
          }
          this.isADTsLoading = false;
        })
        .catch(error => {
          this.isADTsLoading = false;
          this.$ionic.toastController
            .create({
              header: "Error!",
              message: "There was a problem getting data.",
              duration: 4000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    async getDetails(id) {
      const method = "get";
      const path = document.config.patientApi + "event/" + id;
      this.isADTsLoading = true;

      try {
        const eventResponse = await httpSend({
          path,
          method,
          authToken: true
        });
        this.isADTsLoading = false;

        if (eventResponse.status != 200) {
            throw new Error("Unreachable");
          }
          this.openModalNotification(this.sortObjectKeys(eventResponse.data), this.beneficiaryId);
      } catch (error) {
        this.isADTsLoading = false;
        
        const toast = await this.$ionic.toastController.create({
          header: "Error!",
          message: "There was a problem getting data.",
          duration: 4000,
          position: "top"
        });
        toast.present();
        khanSolo.log(error);
      }
    },
    sortObjectKeys(rawObj) {
      if (!rawObj) {
        return {};
      }
      return Object.keys(rawObj)
        .sort()
        .reduce((obj, key) => {
          obj[key] = rawObj[key];
          return obj;
        }, {});
    },
    getStatusClass(statusLevel = 0) {
      return findClass(statusLevel);
    },
    checkNeedConfirmed(sdoh) {
      if (!sdoh.assessments) {
        return false;
      }

      const assessmentMade = moment(sdoh.assessments[0].createdAt);
      const sdohUpdated = moment(sdoh.lastUpdated);

      if (assessmentMade.isAfter(sdohUpdated)) {
        return true;
      } else {
        return false;
      }
    }
  },
  computed: {
    careTeam: function() {
      return this.currentPatient.payload.careTeamMemberDetails.filter(function(member) {
        return member.careTeamMember == true;
      });
    },
    beneficiaryId: function() {
      return this.currentPatient.payload.beneficiary.id;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.grid-layout {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  grid-template-columns: repeat(1, [col-start] 1fr);

  @media only screen and (min-width: 1050px) {
    grid-template-columns: repeat(2, [col-start] 1fr);
  }

  @media only screen and (min-width: 1400px) {
    grid-template-columns: repeat(3, [col-start] 1fr);
  }
}
.column-grid-layout {
  row-gap: 10px;
  display: flex;
  flex-flow: column;
}

.dx-container {
  min-height: 300px;
  max-height: 459px;
  overflow: auto;

  background:
    /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background:
    /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
  
}

.icon-col {
  text-align: center;
  max-width: 10%;
}

/* Task Checkbox ---------------- */
.prime-task-checkbox {
  height: 24px;
  width: 24px;
  display: block;
  position: relative;
}
.prime-task-checkbox div {
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  cursor: pointer;
}
.prime-task-checkbox div::before,
.prime-task-checkbox div::after {
  content: "";
  display: block;
  transition: all 0.2s ease-in-out;
}
.prime-task-checkbox div::before {
  height: 20px;
  width: 20px;
  border: 2px solid var(--ion-color-medium-shade);
  border-radius: 20px;
  background: #ffffff;
  cursor: pointer;
}
.prime-task-checkbox div:hover::before {
  border: 2px solid var(--ion-color-primary);
}
.prime-task-checkbox div::after {
  height: 5px;
  width: 10px;
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  transform: rotate(-45deg);
  position: absolute;
  position: absolute;
  top: 7px;
  left: 6px;
}
.prime-task-checkbox.checked div:before {
  background: var(--ion-color-primary);
  border: 2px solid var(--ion-color-primary);
}

.prime-card {
  background-color: #f2f3f5;
  border-radius: 8px;
}
.center {
  margin: 0 auto;
}

.text-capitalize {
  text-transform: capitalize;
}

.pill {
  padding: 12px 14px;
  flex: 4;
}

.pill:first-letter {
  text-transform: capitalize;
}

.risk-pill {
  padding: 5px;
  min-height: 2.7rem;
  font-weight: bold;
}
.risk-pill.high {
  background: rgb(230, 30, 37);
  color: var(--ion-color-white);
}
.risk-pill.moderate {
  background: rgb(253, 172, 42);
  color: var(--ion-color-black);
}

.risk-pill.low {
  background: rgb(91, 184, 45);
  color: var(--ion-color-white);
}

.no-need {
  color: black;
}

.need-confirmed {
  border: 2px solid rgb(230, 30, 37);
  background: rgb(230, 30, 37);
  color: var(--ion-color-white);
}

.flex-section {
  display: flex;
  align-items: center;
}

.indicates {
  flex: 1;
  margin-right: 5px;
  height: 24px;
}

.indicates svg {
  width: 25px;
  height: 25px;
}

.main-val {
  flex: 5;
  text-align: center;
}

.trend.pill {
  flex: 1;
  padding: 5px 9px;
  text-align: center;
  font-size: smaller;
  font-weight: bold;
  text-transform: capitalize;
  background: rgba(255, 255, 255, 0.8);
}

.trend svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 4px;
}

.unclickable {
  cursor: auto;
}

.tooltip .tooltip-inner .v-tooltip__content {
  width: 200px !important;
}
</style>
