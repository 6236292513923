<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <h5 slot="start">Event Notification Detail</h5>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-modal-content">
      <ion-row v-if="hasPatient">
        <ion-col>
          <div>
            <PatientBanner
              :currentPatient="currentPatient"
              mini="true"
              style="margin-bottom: 10px"
            />
          </div>
        </ion-col>
      </ion-row>
      <ion-row v-if="hasPatient">
        <ion-col>
          <div class="margin-bottom-ten">
            <ion-card
              class="margin-death margin-top-zero"
              style="overflow: initial; margin-top: 0 !important"
            >
              <patient-contact-banner
                style="width: 100%;"
                :contact_number.prop="this.patientContactNumber"
                :primary_practitioner.prop="this.generalPractitioner"
              />
            </ion-card>
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col
          class="ion-align-self-stretch text-center card red-bg text-white display-flex valign text-left box-size"
        >
          <div class="full-width valign pad-left-twenty pad-right-twenty ">
            <IconWarning class="icon-spacing" />
            <span class="text-bold text-title margin-left-five">{{ detailsObj.eventTitle }}</span>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="m-t-15">
        <ion-col>
          <div class="title-font m-t-15">Date</div>
          <div class="text-bold">{{ utcFormat(detailsObj.eventDate) }}</div>
          <div class="title-font m-t-15">Admission Type</div>
          <div class="text-bold">{{ detailsObj.admissionType }}</div>
          <div class="title-font m-t-15">Patient ID</div>
          <div class="text-bold">{{ detailsObj.patientId }}</div>
          <div class="title-font m-t-15">Visit ID</div>
          <div class="text-bold">{{ detailsObj.visitNumber }}</div>
          <div class="title-font m-t-15">Location</div>
          <div class="text-bold">
            {{ fillLocationRoomPOC(detailsObj.location, detailsObj.room, detailsObj.pointOfCare) }}
          </div>
        </ion-col>
        <ion-col>
          <div class="title-font m-t-15">Attending Dr.</div>
          <div class="text-bold">{{ detailsObj.attendingDoctor }}</div>
          <div class="title-font m-t-15">Admitting Dr.</div>
          <div class="text-bold">{{ detailsObj.admittingDoctor }}</div>
          <div class="title-font m-t-15">Consulting Dr.</div>
          <div class="text-bold">{{ detailsObj.consultingDoctor }}</div>
          <div class="title-font m-t-15">Discharge Disposition</div>
          <div class="text-bold">{{ detailsObj.dischargeDisposition }}</div>
          <div class="title-font m-t-15">Patient Class</div>
          <div class="text-bold">{{ detailsObj.patientClass }}</div>
          <div class="title-font m-t-15">Sending Facility</div>
          <div class="text-bold">{{ detailsObj.sendingFacilityName }}</div>
          <div class="title-font m-t-15">Source</div>
          <div class="text-bold">{{ detailsObj.eventType }}</div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div class="title-font m-t-15">Dx Codes</div>
          <div v-if="detailsObj.diagnosisCodes.length > 0" class="text-bold">
            <div v-for="diagnosisCode in detailsObj.diagnosisCodes" :key="diagnosisCode.code">
              {{ diagnosisCode.code + " | " + diagnosisCode.description }}
            </div>
          </div>
        </ion-col>
      </ion-row>
      <hr class="m-t-15" />
      <ion-row>
        <ion-col>
          <div class="title-font">Instructions</div>
          <div v-html="detailsObj.instructions" class="text-bold"></div>
        </ion-col>
      </ion-row>
      <ion-row class="">
        <ion-col>
          <div class="title-font m-t-15">Chronic Conditions</div>
          <div class="text-bold m-t-5">
            <div v-if="detailsObj.chronicConditions.length > 0">
              <IconInfoCircle
                v-tooltip.top="{
                  content:
                    '<div> Patients reported chronic conditions: </div>' + chronicTooltip + '</div>'
                }"
                class="prime-table-inline-icon prime-table-hover-icon"
              />
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row>
          <ion-col>
            <button @click="closeModal()" class="prime-button button-block">
              <span>Close</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import IconInfoCircle from "@/components/Global/Icons/IconInfoCircle";
import IconWarning from "@/components/Global/Icons/IconWarning";
import PatientBanner from "@/components/Settings/Patient/PatientBanner";
import moment from "moment";

export default {
  name: "ModalEventNotification",
  props: {
    beneId: Number,
    title: String,
    item: Object,
    detailsObj: [],
    chronicTooltip: String
  },
  data() {
    return {
      hasPatient: false,
      currentPatient: null,
      patientContactNumber: "",
      generalPractitioner: ""
    };
  },
  async created() {
    this.currentPatient = await this.getCurrentPatient(this.beneId);

    this.hasPatient = true;

    const accountChannelSystemKind = {
      sms: "sms",
      voice: "phone",
      email: "email",
      fax: "fax",
      direct: "other"
    };

    const accountChannelUseKind = {
      sms: "mobile"
    };

    const mapFhirTelecom = accountChannel => {
      return {
        system: accountChannelSystemKind[accountChannel?.channel],
        value: accountChannel?.identifier,
        ...(accountChannelUseKind[accountChannel?.channel] && {
          use: accountChannelUseKind[accountChannel?.channel]
        }),
        ...(accountChannel?.priority && { rank: accountChannel?.priority })
      };
    };

    const acccountChannelTelecoms =
      this.currentPatient?.payload?.accountChannels?.map(c => mapFhirTelecom(c)) ?? [];

    // If 'sms' exists use that
    // Else use 'phone'
    this.patientContactNumber = acccountChannelTelecoms.find(e => {
      return (
        e.system === accountChannelSystemKind[e.system] ||
        e.system === accountChannelSystemKind["phone"]
      );
    })?.value;
    this.generalPractitioner = this.makePcpName(this.currentPatient?.payload?.patientDetails);
  },
  mounted() {
    this.displayChronicConditions();
  },
  components: {
    IconWarning,
    IconInfoCircle,
    PatientBanner
  },
  methods: {
    /** PCP name mapping consistent with the PatientBanner. */
    makePcpName(patientDetails) {
      const providerName = `${patientDetails.clinical_provider_first_name} ${patientDetails.clinical_provider_last_name}`;
      return providerName.length > 1 ? providerName : "-";
    },
    getCurrentPatient: async function(beneId) {
      const method = "get";
      const path = document.config.patientSettings + beneId;
      try {
        const response = await httpSend({ path, method, authToken: true });
        return response.data;
      } catch (error) {
        const errorToast = await this.$ionic.toastController.create({
          header: "Could not get current patient. Please try again later.",
          message: error,
          duration: 5000,
          position: "top"
        });
        errorToast.present();
        khanSolo.log(error);
      }
    },
    closeModal() {
      this.$ionic.modalController.dismiss();
    },
    utcFormat(date) {
      return moment(date)
        .utc()
        .format("MM/DD/YYYY - h:mmA");
    },
    displayChronicConditions() {
      var tooltipText = "";

      for (let i = 0; i < this.detailsObj.chronicConditions.length; i++) {
        tooltipText +=
          "<div>" +
          this.detailsObj.chronicConditions[i].chronic_condition +
          ": " +
          this.detailsObj.chronicConditions[i].parent_chronic_condition +
          "</div>";
      }
      this.chronicTooltip = tooltipText;
    },
    fillLocationRoomPOC(loc, room, poc) {
      var locationString = "";

      if (loc != "" && loc != null) {
        locationString = "Loc: " + loc + " | ";
      }
      if (room != "" && room != null) {
        locationString += "Room: " + room + " | ";
      }
      if (poc != "" && poc != null) {
        locationString += "POC: " + poc;
      } else {
        locationString = locationString.substring(0, locationString.length - " | ".length);
      }

      return locationString;
    }
  }
};
</script>

<style scoped>
.red-bg {
  background-color: #e02020;
}
.box-size {
  height: 55px;
  border-radius: 6px;
}
.text-spacing {
  position: relative;
  top: -8px;
  font-weight: bold;
}
.icon-spacing {
  margin-right: 8px;
  fill: white;
}
.prime-modal-content {
  border: none;
  padding: 0 20px 5px;
}
.title-font {
  color: var(--ion-color-dark);
  margin-right: 10px;
  font-size: 10px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-b-25 {
  margin-top: 15px;
}
.btn-conditions {
  color: #0f5db8;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
}
.message {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
ion-footer {
  padding: 20px;
}

.meta {
  color: var(--ion-color-medium-shade);
  font-size: smaller;
}

.by {
  font-size: smaller;
}

.notes {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  height: 390px;
  width: 105%;
}

.notes li {
  margin-bottom: 20px;
}

.add-notes-btn {
  position: absolute;
  top: 4px;
  right: 10px;
  height: 31px;
}

.note-input {
  position: absolute;
  bottom: 10px;
  width: 90%;
}

.rejection-note {
  height: 100%;
}

.notifications-well {
  width: 100%;
}
.marking-div {
  height: 85%;
}
</style>
